import moment from "moment-timezone";

export const START_DAY_OF_A_MONTH = moment().startOf("month").toDate();
export const END_DAY_OF_A_MONTH = moment().endOf("month").toDate();

export const defaultMaxHoursSick = 0;
export const defaultMaxHoursMiss = 0;
export const defaultMaxHoursHoliday = 0;

//CONSTANTS UTILE POUR LE CONGE
export const MALADIE = "Maladie";
export const ABSENCE = "Absence";
export const VACANCE = "Vacances";

export const minCongeHours = 1;
export const minWorkingHoursMinutes = 1; //Valeur minimal d'horaire travaillée autorisée en minute

export const defaultStartHour = "07:30";
export const defaultEndHour = "23:30";

export const defaultHourFormat = "hh:mm A";

export const maFicheEmploye = "ma-fiche-employe";
export const addEmployer = "ajout-employe";

export const dossierEmployes = "dossiers-employes";
export const employeDocumentation = "employe-documentation";
export const entrepriseDocumentation = "entreprise-documentation";

export const defaultImageUrl = "https://cdn.pixabay.com/";
// export const serverUri = (window.location.href.includes("local"))? "http://localhost:8080" : (window.location.href.includes("dev2020"))? "https://dev2020.altee.com/api/" : "https://app.altee.com/api/";
export const serverUri = process.env.REACT_APP_SERVER_URI;

export const congeCategoriesPossibleValue = ["Absence", "Vacances", "Maladie"];
export const congeStatusPossibleValues = ["en attente", "Acceptée", "Refusée"];
export const defaultRemainingDayValue = "-";

export const defaultUserImage =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

export const BASE_COLOR = {
  firstColor: "#fcb698",
  secondColor: "#fa966b",
  loaderBlue: "#4472a3",
  loaderGreen: "#73c7b7",
  loaderParams: "#1a4e63",
};

export const GRAPH_CONF = {
  defaultBorderColor: "#d4d3d2",
  maxLabelLength: 40,
};

export const TAILWIND_RESPONSIVE = {
  "2xl": 1536,
  xl: 1280,
  lg: 1024,
};

export const MONTH_STR_EN = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const MONTH_STR_FR = [
  "Janv",
  "Févr",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juil",
  "Août",
  "Sept",
  "Oct",
  "Nov",
  "Déc",
];

export const DATE_STR_FORMAT = {
  fr: "DD/MM/YYYY",
  en: "YYYY/MM/DD",
};
export const INIT_EVOLUTION = [
  moment().subtract(5, "years").get("year"),
  moment().get("year"),
];
export const LOGO_ALTEE =
  "https://uploads-ssl.webflow.com/639b7bb3508f5536915f80c0/639b843e4fb1b00671141455_Logo.svg";
//status post
export const STATUS_FINISHED = "INACTIF";
// Model name constants
export const MODEL_NAME_EMPLOI_CERTIFICATION = "EmploiCertifications";
export const MODEL_NAME_EMPLOI_FORMATION = "EmploiFormation";
export const MODEL_NAME_CONGE = "Conge";
export const MODEL_NAME_NEW_FOLDER = "Employer";
export const MODEL_NAME_ENTERPRISE = "Entreprise";
export const MODEL_NAME_WORKING_HOUR_POINTING = "WorkingHour";
export const MODEL_NAME_USER_EXTERNAL = "UserExternal";
export const MODEL_NAME_MATERIAL = "Material";

export const PERMISSION_ADMIN = "Administrateur";
export const PERMISSION_EMPLOYEE = "Employé";
export const ROLE_ADMIN = "admin";
export const ROLE_EMPLOYER = "employer";

// Congé status
export const CONGE_STATUS_APPROUVED = "Acceptée";
export const CONGE_STATUS_REFUSED = "Refusée";

// Socket variables
export const SOCKET_TOGGLE_TIMECLOCK = "toggleTimeClock";
export const SOCKET_TOGGLE_ACTIVE_TIMECLOCK = "toggleActiveTimeClock";

// Socket variables for notification
export const SOCKET_NEW_NOTIFICATION = "newNotification";
export const SOCKET_LOGIN = "login";
export const SOCKET_NEWS = "news";
export const SOCKET_ON_NEW_NOTIFICATION = "onNewNotification";
export const RELOAD_NOTIFICATION = "reload_notification";
// Notification type
export const NOTIFICATION_TYPE_ENTERPRISE = "Entreprise";
export const NOTIFICATION_TYPE_USER = "Users";

// Notification constants
export const NOTIFICATION_NEWS = "News";
export const NOTIFICATION_COMMENT = "Comment";
export const NOTIFICATION_CONGE = "Conge";
export const NOTIFICATION_AGENDA = "Agenda";
export const NOTIFICAITON_NEWS_LIKE = "NewsLike";
export const NOTIFICATION_NEWS_COMMENT = "NewsComment";
export const NOTIFICATION_NEWS_VISIBILITY = "NewsVisibility";
export const NOTIFICATION_NEWS_VUE = "NewsVue";
export const NOTIFICATION_WORKING_HOUR = "WorkingHour";
export const NOTIFICATION_DOCUMENT_STORAGE = "DocumentStorage";
export const NOTIFICATION_EMPLOIE_CERTIFICATION = "EmploiCertifications";
export const NOTIFICATION_EMPLOIE_COURSE = "EmploiCourses";
export const NOTIFICATION_EMPLOIE_FORMATION = "EmploiFormation";
export const NOTIFICATION_HORAIRE = "Agenda";
export const MODEL_CANDIDATE = "UserExternal";
export const NOTIFICATION_ONBOARDING_EMPLOYE_TASK = "OnboardingEmployeTask";
export const MODEL_REIMBURSEMENT = "Reimbursement";

export const NOTIFICATION_MSG_EXCEP = [
  "your_candidate",
  "onboarding_task_overdue_task",
  "onboarding_task_warning_2day_before",
  "onboarding_task_reminding",
  "assignement_onboarding_task",
  "evaluation_todo",
  "evaluation_todo_late",
  "alert_event_unavailability",
  "task_canceled",
  "task_performed",
];

export const REGEX_VALID_DECIMAL_NUMBER = /^\d+(\.\d+)?/;
export const VALID_DATE =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
export const REGEX_TIME = /^((1[0-2])|[1-9]):[0-5][0-9]\s(AM|PM)$/;
export const REGEX_TIME_TYPE =
  /^([1-9]|1?[0-2])?(^([1-9]|1?[0-2])?:)?(^((1?[0-2]|[1-9])?:)([0-9]|[0-5][0-9]))?(^((1?[0-2]|[1-9])?:?[0-5]?[0-9]?)?\s)?(^(((1?[0-2]|[1-9])?:?[0-5]?[0-9]?)?\s?)?(A|P|M|AM|PM))?$/;
//Regex
export const REGEX_NAME = /^(?![\s.]+$)[a-zA-Zéàçèôôöêëûüîïœ\-\s.]*$/;
// export const REGEX_PHONE = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
export const REGEX_PHONE =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const REGEX_PHONE2 =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
export const REGEX_PHONE_INVERSE = /(?!(-|\+))[^0-9.]/g;
// export const REGEX_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const REGEX_EMAIL =
  /^[\w\áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ+-]+([\.]?[\w\áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ-]+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
export const REGEX_ALL_IMAGE =
  /\.(3fr|arw|avif|bmp|cr2|crw|cur|dcm|dcr|dds|dng|erf|exr|fax|fts|g3|g4|gif|gv|hdr|heic|heif|hrz|ico|iiq|ipl|jbg|jbig|jfi|jfif|jif|jnx|jp2|jpe|jpeg|jpg|jps|k25|kdc|mac|map|mef|mng|mrw|mtv|nef|nrw|orf|otb|pal|palm|pam|pbm|pcd|pct|pcx|pdb|pef|pes|pfm|pgm|pgx|picon|pict|pix|plasma|png|pnm|ppm|psd|pwp|raf|ras|rgb|rgba|rgbo|rgf|rla|rle|rw2|sct|sfw|sgi|six|sixel|sr2|srf|sun|svg|tga|tiff|tim|tm2|uyvy|viff|vips|wbmp|webp|wmz|wpg|x3f|xbm|xc|xcf|xpm|xv|xwd|yuv|3FR|ARW|AVIF|BMP|CR2|CRW|CUR|DCM|DCR|DDS|DNG|ERF|EXR|FAX|FTS|G3|G4|GIF|GV|HDR|HEIC|HEIF|HRZ|ICO|IIQ|IPL|JBG|JBIG|JFI|JFIF|JIF|JNX|JP2|JPE|JPEG|JPG|JPS|K25|KDC|MAC|MAP|MEF|MNG|MRW|MTV|NEF|NRW|ORF|OTB|PAL|PALM|PAM|PBM|PCD|PCT|PCX|PDB|PEF|PES|PFM|PGM|PGX|PICON|PICT|PIX|PLASMA|PNG|PNM|PPM|PSD|PWP|RAF|RAS|RGB|RGBA|RGBO|RGF|RLA|RLE|RW2|SCT|SFW|SGI|SIX|SIXEL|SR2|SRF|SUN|SVG|TGA|TIFF|TIM|TM2|UYVY|VIFF|VIPS|WBMP|WEBP|WMZ|WPG|X3F|XBM|XC|XCF|XPM|XV|XWD|YUV)$/;
export const REGEX_VALID_FILES =
  /\.(jpg|jpeg|png|gif|bmp|webp|ico|JPG|JPEG|PNG|GIF|BMP|WEBP|ICO|pdf|PDF|doc|DOC|csv|xslx|docx|mp4|webm|mp3|CSV|XSLX|DOCX|MP4|WEBM|MP3|msg|MSG)$/;
export const REGEX_NOT_NUMBER = /(?!(\.))[^0-9.]/g;
export const REGEX_NUMBER = /^\d+$/g;
export const HEXADECIMAL_REGEX = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
export const RGBA_REGEX = /^rgb\s*\(((\d{1,3}),\s*){2,3}((\d{1,3})\))$/i;
export const REGEX_LINK =
  /^(?:http(s)?:\/\/)?[\w\.\-]+(?:\.[\w\.\-]+)+[\w\-\._~:/?#\[\]@!\$&'\(\)\*\+,;=.]+$/;
export const REGEX_CHECK_LINKS =
  /(https?:\/\/)[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w]*)?(\?[^\s]*)?/gi;
export const REGEX_VALID_DOMAIN_NAME =
  /^[a-z0-9]+(?:[\-.][a-z0-9]+)*\.[a-z]{2,6}$/i; ///^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/
export const STRONG_PASSWORD_REGEX = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})",
);
export const REGEX_POST_NUMBER = /^[0-9]{3,6}$/;
export const REGEX_TRANSIT_NUMBER = /^[0-9]{5}$/;
export const REGEX_ACCOUNT_NUMBER = /^\d{7,12}$/;
export const REGEX_SOCIAL_NUMBER = /^[0-9]{9}$/;
export const IMAGE_TYPE_FILES = ".jpg,.jpeg,.png,.gif,.bmp,.webp,.ico,.jfif";
export const VALID_TYPE_FILES =
  ".jpg,.jpeg,.png,.gif,.bmp,.webp,.ico,.pdf,.doc,.csv,.xslx,.docx,.mp4,.webm,.mp3,.msg,.xlsx,.jfif";
export const VIDEO_TYPE_FILES =
  ".avi,.mp4,.mkv,.mov,.wmv,.flv,.webm,.mpeg,.mpg,.3gp,.ogv',.m4v,.mts,.m2ts,.ts,.divx,.xvid";
// par absurde
export const INVALID_TYPE_FILES =
  " .php , .js , .exe , .crx , .json , .sql .jsx  ";

export const NUMBER_ONLY = /\D/g;
export const FLOAT_ONLY = /[^0-9.]/g;
//file type in file manager
export const FileType = { file: "file", folder: "folder" };

export const PATTERN_FILENAME = /^[^<>:"\/\\|?*\x00-\x1F]+$/;
export const REGEX_FILENAME = /^[0-9a-z_\-]{3,255}\.([a-z0-9]{2,5})$/;

//constants for socket
export const CONSTANT_SOCKET_FOR_EMPLOYEURD = "reload_employeurD";
export const CONSTANT_SOCKET_FOR_USER = "reload_user";
export const CONSTANT_SOCKET_FOR_EMPLOYEE = "reload_employe";
export const CONSTANT_SOCKET_FOR_EMPLOYEE_AND_SALARY =
  "reload_employe_and_salary";
export const CONSTANT_SOCKET_FOR_DROPDOWN_HIERARCHICAL_IN_EMPLOYEE_AND_SALARY_ONLY =
  "reload_dropdown_in_employee_and_salary";
export const CONSTANT_SOCKET_FOR_ACCESS_PROFILE = "reload_access_profile";
export const CONSTANT_SOCKET_FOR_PLANNED_HOURS = "reload_planned_hours";
export const CONSTANT_SOCKET_FOR_CONGES = "reload_conges";
export const CONSTANT_SOCKET_FOR_EMPLOYER_HOURS = "reload_employer_hours";
export const CONSTANT_SOCKET_FOR_WORKING_HOURS = "reload_working_hours";
export const CONSTANT_SOCKET_FOR_TIMECLOCK = "reload_timeclock";
export const CONSTANT_SOCKET_FOR_PERMISSION = "reload_permission";
export const CONSTANT_SOCKET_FOR_POSTE = "reload_poste";
export const CONSTANT_SOCKET_FOR_GROUPS = "reload_group";
export const CONSTANT_SOCKET_FOR_EMPLACEMENT = "reload_emplacement";
export const CONSTANT_SOCKET_FOR_ENTERPRISE = "reload_enterprise";
export const CONSTANT_SOCKET_FOR_NEWS = "reload_news";
export const CONSTANT_SOCKET_FOR_NOTE = "reload_note";
export const CONSTANT_SOCKET_FOR_COURSE = "reload_course";
export const CONSTANT_SOCKET_FOR_CERTIFICATION = "reload_certification";
export const CONSTANT_SOCKET_FOR_FORMATION = "reload_emploi_formation";
export const CONSTANT_SOCKET_FOR_DOCUMENTS_STORAGES = "reload_files";
export const SOCKET_DOCUMENTS_STORAGE_EMPLOYEE = "reload_document_storage";
export const CONSTANT_SOCKET_HOUR_DATA = "reload_hours_data";
export const RELOAD_HIRING_ANNIVERSARY = "reload_hiring_anniversary";
export const CONSTANT_SOCKET_FOR_MATERIAL = "reload_material";
export const RELOAD_ASSIGNEMENT = "reload_assignement";
export const JOB_EVENT = "reload_job";
export const DAY_OFF_EVENT = "reload_day_off";
export const CONSTANT_SOCKET_BULLES = "reload_bulles";
export const CONSTANT_SOCKET_TAGS = "reload_tags";
export const CONSTANT_SOCKET_APPLICATION = "reload_application";
export const RELOAD_PROTOCOL = "reload_protocol";
export const RELOAD_EMPLOYE_PROTOCOL = "reload_employe_protocol";
export const RELOAD_EMPLOYE_TASK = "reload_employe_task";
export const RELOAD_EMPLOYE_FLOW = "reload_unavalaibility";
export const RELOAD_STATS = "reload_stats";
export const RELOAD_REFUSAL_REASONS = "reload_refusal_reasons";
export const RELOAD_APPLICATION_SOURCES = "reload_application_sources";
export const RELOAD_EXPENSES = "reload_expenses";
export const RELOAD_ITINERARY = "reload_itinerary";
export const RELOAD_REIMBURSEMENT = "reload_reimbursment";
export const RELOAD_REIMBURSEMENT_LISTS = "reload_reimbursment_lists";
export const RELOAD_PIPELINE_TEMPLATE = "reload_pipeline_model";
export const RELOAD_FORM_TEMPLATE = "reload_application_form";
export const RELOAD_EMAIL_TEMPLATE = "reload_email_template";
export const RELOAD_POST_TEAM = "reload_post_team";
export const RELOAD_POST_LOCATION = "reload_post_location";
export const RELOAD_JOB_PIPELINE = "reload_job_pipeline";
export const RELOAD_EVALUATION = "reload_evaluation";
export const RELOAD_QUESTION = "reload_question";
export const RELOAD_EVALUATION_REQUEST = "reload_evaluation_request";
export const RELOAD_EVALUATION_ALERT = "reload_evaluation_alert";
export const RELOAD_DOCUMENTATION = "reload_documentation";
export const JOBBOARD_DATA_EVENT = "reload_jobboard_data";
export const RELOAD_ORGANIGRAM = "reload_organigram";
export const RELOAD_EVALUATION_NOTE = "reload_evaluation_note";
export const RELOAD_WORKED_HOURS_TYPES = "reload_workedHoursTypes";
export const RELOAD_EVALUATION_OBJECTIVE = "reload_evaluation_objective";
export const RELOAD_TYPE_DEMISSION = "reload_demission";
export const RELOAD_HEALTH = "reload_emploiHealth";
export const RELOAD_INDIVIDUAL_OBJECTIVE = "reload_individual_objective";
export const RELOAD_INDIVIDUAL_OBJECTIVE_TYPE =
  "reload_individual_objective_type";
export const RELOAD_OBJECTIVE_PERIOD = "reload_objective_period";
export const RELOAD_USER_CONNECTED_ONLY = "reload_user_connected_only";
export const RELOAD_EXTERNAL_LINKS = "reload_external_links";
export const RELOAD_ORGANISATOR_PROJECT = "reload_organisator_project";

//constants size of file uploaded
export const MAX_SIZE_FOR_INPUTFILE_IN_MODAL_CERTIFICATION = 40485760; // 40 Mo
// export const MAX_SIZE_FOR_FILE_MANAGER = 314572800; // 300 Mo
//Illimite
export const ILLIMITE = "Illimité";

//AGE MINIMUM
export const AGE_MINIMUM = 15;
//STATUS
export const ACTIF = "Actif";
export const TEMPORAIREMENT_INACTIF = "Inactif Temporairement";
export const DEFINITIVEMENT_INACTIF = "Inactif Définitivement";
//ERROR
export const ERROR = "error";
//MESSAGE
export const MSG_INVALID_FILE_TYPE =
  "Veuillez sélectionner un type de fichier valide.";
export const MESSAGE_DE_VALIDATION_SI_L_EMAIL_EXISTE_DEJA =
  "Le courrier existe déjà";
export const MESSAGE_D_ERREUR_DE_VERIFICATION_D_EMAIL =
  "Erreur de vérification de l'email";
export const MESSAGE_DE_VALIDATION_S_IL_Y_A_DES_ERREURS_A_PART_LES_CHAMPS_REQUIS =
  "Veuillez regler l(es) erreur(s)!";
export const MESSAGE_DE_VALIDATION_POUR_LES_CHAMPS_REQUIS =
  "Pour pouvoir enregistrer, les champs dotés d'un astérisque doivent être remplis";
export const MESSAGE_FOR_EMPLOYEE_INACTIVE_DEFINITELY_PART_1 =
  "La modification du statut a été enregistrée à la date d'aujourd'hui";
export const MESSAGE_FOR_EMPLOYEE_INACTIVE_DEFINITELY_PART_2 =
  "Cette date est modifiable dans le menu 'parcours et certifications'";
export const MESSAGE_FOR_HIRING_DATE_AFTER_DATE_OF_BIRTH =
  "La date d'embauche doit être supérieure à la date de naissance";
export const MESSAGE_FOR_DATE_OF_CREATION_OF_THE_ENTERPRISE_AFTER_HIRING_DATE =
  "La date d'embauche doit être supérieure à la date de création de l'entreprise";
export const MESSAGE_TO_INFROM_USER_THE_PRIORITY =
  "Veuillez remplir les informations personnelles et emploi et salaires avant de remplir les autres onglets";
//IMAGES
export const BLANK_PROFILE_PICTURE =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

//Number of item per page after first requestexport
export const ITEM_PER_PAGE = 10;

// reajustement of component below the scroll
export const LEEWAY_SIZE = 100;
export const PADDING_BOTTOM = 9;

//variable for recruitments
export const PIPELINE = "pipelines";
export const AVAILABLE_POSTES = "availablePostes";
export const RECRUITS = "applications";
export const CV_BANK = "cvBank";
export const EMAILS = "emails";
export const CARREER_PAGE = "careerPage";
export const MODELE = "modele";
export const STATISTICS = "statistics";

//Variable for documentations
export const DOCUMENTS = "documents";
export const ORGANIGRAMME = "organigramme";

export const DOCUMENTS_EMPLOYEE = "DDocumentation";
export const ORGANIGRAMME_EMPLOYEE = "DOrganigram";

// NUMBER CHARACTER
export const NUMBER_CHARACTER = 60;
// type of message of recruitments
export const RECRUITE_CANDIDATE = "recruit candidate";
export const REJECTED_CANDIDATE = "rejected candidate";
export const CANDIDATE_PASSED_TO_NEXT_STEEP = "recruit candidate";
export const SUCCESS_ACTION = "success";
export const WARNING_ACTION = "warning";
export const INFO_ACTION = "info";
// opacity value for card's pipeline
export const OPACITY_FOR_BORDER_TOP_BACKGROUND = 0.9;
export const OPACITY_COLOR_FONT_FOR_BADGE = 1;
export const OPACITY_BACKGROUND_FOR_BADGE = 0.5;

export const ANNIVERSARY_BIRTH_TYPE = "birth";
export const ANNIVERSARY_HIRING_TYPE = "hiring";

export const ATTRIBUTION_TYPE_EMPLOYER = "Employer";
export const ATTRIBUTION_TYPE_GROUPE = "Groupe";
export const ATTRIBUTION_TYPE_EMPLACEMENT = "Emplacement";
// constants for material
export const DATE_AQUISITION_TYPE = [
  { _id: "dateWarranty", title: "Fin de garantie" },
  { _id: "dateContrat", title: "Terme du contrat" },
  { _id: "attributionDate", title: "Assigné le" },
];

export const STATE_MATERIAL = [
  { _id: "neuf", title: "Neuf" },
  { _id: "usage", title: "Usagé" },
  { _id: "endommage", title: "Endommagé" },
  { _id: "nonFonctionnel", title: "Non-fonctionnel" },
];
export const STATUS_MATERIAL = [
  { _id: "libre", title: "Libre", color: "actif" },
  { _id: "assigne", title: "Assigné", color: "blue" },
  { _id: "assignAndSecurity", title: "Assigné & sécurisé", color: "babyblue" },
  { _id: "reserve", title: "Reservé/en attente", color: "yellow" },
  { _id: "indisponible", title: "Indisponible temporairement", color: "red" },
  { _id: "perdu", title: "Perdu/volé", color: "purple" },
  { _id: "retire", title: "Retiré de l'inventaire", color: "inactive" },
];

export const CATEGORY_MATERIAL = [
  { _id: "ordinateur", title: "Ordinateur" },
  { _id: "telephone", title: "Téléphone" },
  { _id: "matElec", title: "Matériel électronique" },
  { _id: "matInfo", title: "Matériel informatique" },
  { _id: "matResequ", title: "Matériel d'infrastructure réseau" },

  { _id: "matProtection", title: "Equipement de protection" },
  { _id: "matPersonne", title: "Equipement de la personne" },
  { _id: "vetementEtAccessoire", title: "Vêtements et accessoires" },

  { _id: "vehicule", title: "Véhicule" },
  { _id: "autreVehicule", title: "Autres équipement motorisés" },

  { _id: "mobilier", title: "Mobilier/Agencement" },
  { _id: "matBureau", title: "Equipement de bureau" },

  { _id: "machine", title: "Machines et équipements" },
  { _id: "outillage", title: "Matériel et outillage" },
];

export const DECLARATION_DES_HEURES = "Déclaration des heures";
export const POINTAGE_SANS_PHOTO = "Pointage sans photo";
export const POINTAGE_AVEC_PHOTO = "Pointage avec photo";
export const AUCUN = "Aucun";
export const DECLARATION_POINTAGE = "Déclaration/pointage";
export const ENREGISTREMENT_DES_BLOCS_D_HEURES =
  "Enregistrement de blocs d'heures";
export const ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE =
  "Enregistrements multiples des heures";
// Days off
export const PUBLIC_HOLIDAY = "Journée férié";
export const SPECIAL_DAY = "Journée spéciale";
export const KEY_SPECIAL_DAY = "speciale";
export const KEY_PUBLIC_HOLIDAY = "holidays";
export const TAB_RECAPITULATIF_MENSUEL = "recapitulatifMensuel";
export const TAB_REPARTITION = "repartition";
export const TAB_COMPTE_RENDU = "compteRendu";
export const TAB_POINTAGE = "saisieDesHeuresEtPointage";

// Constante pour le custom domain
export const IP_SERVER = process.env.REACT_APP_IP_SERVER;
export const CD_STATUS_CONNECTED = "CONNECTED";
export const CD_STATUS_NOT_CONNTECTED = "NOT_CONNECTED";
export const CD_INSTALLED_SSL = "INSTALLED";
export const CD_NOT_INSTALLED_SSL = "NOT_INSTALLED";
export const CD_STATUS_CREATE_SUCCESS = "SUCCESS";

// Possible value of user data access :
export const SUBORDINATE_EMPLOYERS = "SubEmployers";
export const SUBORDINATE_AND_SIBLINGS = "SubAndSiblings";
export const ALL_EMPLOYERS = "All";

export const CALL_OUT_MESSAGE_DURATION = 3000;

export const WAITING = "En attente";
export const ACCEPTED = "Acceptée";
export const REFUSED = "Refusée";

//Notification type
export const TYPE_ETIQUETTE = "Etiquette";
export const TYPE_MODAL = "Modal";
export const TYPE_PAGE = "Page";

export const TOOLTIP_SALARY = "TOOLTIP_SALARY";
export const TOOLTIP_COURSE = "TOOLTIP_COURSE";
export const TOOLTIP_MATERIAL = "TOOLTIP_MATERIAL";

export const PIPELINES_DEFAULT_TITLES = [
  "Pipeline à 2 étapes",
  "Pipeline à 3 étapes",
  "Pipeline à 4 étapes",
  "Pipeline à 5 étapes",
  "Pipeline à 6 étapes",
  "Pipeline à 7 étapes",
  "Pipeline à 8 étapes",
];

export const MODELE_RESSOURCE = "pages.recruitments.modele";
export const COUNTRY_RESSOURCE = "components.dropdown.dropdownCountry";

export const DOCUMENT_TABS_PERMISSIONS = [
  "DListInfopers",
  "DListEmploisalary",
  "DListDocumentNote",
  "DListCertFormation",
  "DListDocuments",
  "DListMaterial",
  "DListParameters",
];

export const EMPLOYEES_LIST_PERMISSION = "listBuild";

export const GOOGLE_CLIENT_ID =
  "438513092038-27an1o3uubqmsq59pcine14ibkdjj3cv.apps.googleusercontent.com";
export const MICROSOFT_CLIENT_ID = "1aec4b7b-ad84-46c3-948e-9425cfead05e";
export const DOSSIER_EMPLOYER = "/admin/dossiers-employes/";

//News constant
export const MIME_TYPE = ["mp4", "MP4", "mpeg4", "MPGE4"];
export const FILE_TYPE = ["audio", "image", "application"];

//Report constant
export const initReportFilters = {
  startDate: moment()
    .startOf("month")
    .set({ hour: 0, minute: 0, second: 0 })
    .toDate(),
  endDate: moment()
    .endOf("month")
    .set({ hour: 23, minute: 59, second: 59 })
    .toDate(),
};

export const statusColors = {
  reimbursed: "arh-bg-[#4472A3]",
  refused: "arh-bg-[#f67c53]",
  accepted: "arh-bg-[#92e98f]",
};

export const initMonthFilters = {
  startDate: moment()
    .startOf("month")
    .set({ hour: 0, minute: 0, second: 0 })
    .toDate(),
  endDate: moment()
    .endOf("month")
    .set({ hour: 23, minute: 59, second: 59 })
    .toDate(),
};

export const NEWSFILE_RECOGNISED_MIMETYPES = [
  "text/css",
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/gzip",
  "text/html",
  "text/calendar",
  "text/javascript",
  "application/json",
  "application/ld+json",
  "text/plain",
  "application/xml",
  "application/vnd.ms-excel",
  "application/xhtml+xml",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/zip",
  "application/pdf",
  "application/x-httpd-php",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.rar",
  "audio/mpeg",
  "audio/midi audio/x-midi",
  "application/x-7z-compressed",
  "font/woff",
  "font/woff2",
  "audio/webm",
  "audio/wav",
  "font/ttf",
  "font/otf",
  "audio/aac",
];

export const noColor = {
  valueHexadecimale: "",
  rgba: "",
};

export const Evaluation_Format = {
  SKILL_LEVEL_10: "Niveau de compétence sur 10",
  SKILL_LEVEL_5: "Niveau de compétence sur 5",
  NOTE_5: "Note sur 5",
  NOTE_4: "Note sur 4",
  NOTE_10: "Note sur 10",
  NOTE_3_STARS: "Note sur 3 étoiles",
  NOTE_PERCENT: "Note de %",
};

export const Evaluation_Type = {
  COMPETENCE: "Compétence",
  PERFORMANCE: "Performance",
};

export const Evaluation_Request_Status = {
  TO_DO: "A faire",
  IN_PROGRESS: "En cours",
  DONE: "Terminées",
};

export const Evaluation_noteStatus = {
  BEGINER: 0,
  JUNIOR: 20,
  INTERMEDIARY: 50,
  EXPERIENCED: 80,
};

export const EvaluationStatus = {
  BEGINER: "En formation",
  JUNIOR: "Junior",
  INTERMEDIARY: "Intermédiaire",
  EXPERIENCED: "Expérimenté",
};

export const IndividualObjectiveScoreType = {
  RATING_4: "Note sur 4",
  RATING_5: "Note sur 5",
  RATING_10: "Note sur 10",
  RATING_3_STARS: "Note sur 3 étoiles",
};

export const ERROR_UPLOAD_FILES = [
  "rejected_file_type",
  "rejected_max_files",
  "error_file_size",
  "error_validation",
  "error_upload_params",
  "exception_upload",
  "error_upload",
];

export const PatternLinkedin =
  /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(in|company|pub|profile)\/([A-z0-9_-]+\/?)+$/;

export const MODE = [
  {
    _id: "walking",
    label: "Péages",
    value: "WALKING",
  },
  {
    _id: "driving",
    label: "Autoroutes",
    value: "DRIVING",
  },
  {
    _id: "transit",
    label: "Traversiers",
    value: "TRANSIT",
  },
];

export const OPTIONS_GEOLOCALISATION = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

export const DEFAULT_COLUMNS_SELECTED_ON_BANK_CV = [
  {
    _id: 0,
    name: "Nom du candidat",
    value: "firstnameFirstFilter",
  },
  {
    _id: 1,
    name: "Poste appliqué",
    value: "job.title",
  },
  {
    _id: 2,
    name: "Commentaires",
    value: "noteCommentaire",
  },
  {
    _id: 3,
    name: "Expériences",
    value: "noteExperience",
  },
  {
    _id: 4,
    name: "Compétences",
    value: "noteCompetence",
  },
  {
    _id: 5,
    name: "Éducations",
    value: "noteEducation",
  },
  {
    _id: 6,
    name: "Contenu du CV",
    value: "CV.filename",
  },
];

export const EMPLOYEE_BANK_LABEL_OPTIONS = {
  V: {
    BALANCE_HOURS: "Solde vacances (en heures)",
    PAID_HOURS: "Heures payées - vacances (période de référence)",
    PAID_AMOUNT: "Montant payé - vacances (période de référence)",
  },
  M: {
    BALANCE_HOURS: "Solde maladies (en heures)",
    PAID_HOURS: "Heures payées - maladies (période de référence)",
    PAID_AMOUNT: "Montant payé - maladies (période de référence)",
  },
};

export const UPLOAD_LIMIT = 30;

export const ACCESS_LIST_KEYS = {
  canAddFileOnMyProfile: "canAddFileOnMyProfile",
  canAccessLegalGender: "canAccessLegalGender",
  canAccessDateOfBirth: "canAccessDateOfBirth",
  canAccessOriginCountry: "canAccessOriginCountry",
  canAccessLanguagesSpoken: "canAccessLanguagesSpoken",
  canAccessEmails: "canAccessEmails",
  canAccessPhones: "canAccessPhones",
  canAccessAddress: "canAccessAddress",
  canAccessHealthAllergiesFoodPrefs: "canAccessHealthAllergiesFoodPrefs",
  canAccessFirstContact: "canAccessFirstContact",
  canAccessSecondContact: "canAccessSecondContact",
  canAccessCertsLicenses: "canAccessCertsLicenses",
  canAccessInsuranceSocialNumber:"canAccessInsuranceSocialNumber",
  canAccessBankingInformation:"canAccessBankingInformation",
  denyLoginPasswordAccess: "denyLoginPasswordAccess",
  acceptOwnVacanyRequestEnterprise: "acceptOwnVacanyRequestEnterprise",
  updateOwnDevelopmentPlanEmployee: "updateOwnDevelopmentPlanEmployee",
  denyAccessOwnFolderEnterprise: "denyAccessOwnFolderEnterprise",
  isActiveBankingInformationAndInsuranceAccess:
    "isActiveBankingInformationAndInsuranceAccess",
};

export const EXPORT_TYPES = [
  { name: "Informations des employés", _id: "denyExportEmployeeInfo" },
  { name: "Remboursements", _id: "denyExportReimbursements" },
  { name: "Formations", _id: "denyExportTraining" },
  { name: "Heures travaillés - récapitulatifs", _id: "denyExportHoursSummary" },
  {
    name: "Heures travaillés - répartitions par projets",
    _id: "denyExportHoursByProject",
  },
  { name: "Banques de CVs - Candidats", _id: "denyExportCvBanks" },
  { name: "Certifications", _id: "denyExportCertification" },
];
