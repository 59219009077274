
import BaseService from "./base.service";
import http from "../http-common";
import { checkEnterpriseId, getResponseData } from "@helpers/_functions";

class AccessProfileAccess extends BaseService {
    constructor() {
        super("access-profile")
    }
    upsert(data) {
        return http.post(`${this.path}/`, data)
        .then((response) => getResponseData(response));;
      }
    
    getByEnterprise() {
    return http.get(`${this.path}/${checkEnterpriseId()}`)
    .then((response) => getResponseData(response));;
    }
    
    delete(id) {
    return http.delete(`${this.path}/${id}`)
    .then((response) => getResponseData(response));;
    }
}

export default new AccessProfileAccess();