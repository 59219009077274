// import { checkUserId, getResponseData } from "@helpers/_functions";
// import http from "../../http-common";
import BaseService from "../base.service";

class OrganisatorTasksService extends BaseService {
  constructor() {
    super("organisator-project");
  }
}

export default new OrganisatorTasksService();
