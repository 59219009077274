import { getResponseData } from "@helpers/_functions";

import http from "../../http-common";
import BaseService from "../base.service";

class TaskProprety extends BaseService {
  constructor() {
    super("organisator-task-property");
  }
  getByProject(id) {
    return http
      .get(`${this.path}/project/${id}`)
      .then((response) => getResponseData(response));
  }
  duplicate(id) {
    return http.put(`${this.path}/duplicate/${id}`, {
      userId: localStorage.getItem("userId")
    });
  }
}

export default new TaskProprety();
