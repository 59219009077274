import { ERROR } from "@helpers/_constants";
import {
  customizeError,
  getResponseData,
  checkEnterpriseId
} from "../helpers/_functions";
import http from "../http-common";
import BaseService from "./base.service";

class UsersService extends BaseService {
  constructor() {
    super("users");
  }

  login(condition) {
    return http.post(`${this.path}/login`, condition).catch((err) => {
      console.error("Failed to login ", err);
      return err;
    });
  }

  register(userData) {
    return http.post(`${this.path}/register/`, userData);
  }

  verifyExistEmail(userEmail) {
    if (!userEmail.enterpriseId) {
      userEmail.enterpriseId = localStorage.getItem("enterpriseId");
    }
    return http
      .post(`${this.path}/verifyExistEmail/`, userEmail)
      .then((response) => {
        let result = {
          userAlreadyExist:
            response.data.status && response.data.status === ERROR
        };
        if (response.data?.data?.microsoft)
          result.microsoftAccount = response.data.data.microsoft;
        if (response.data?.data?.google)
          result.googleAccount = response.data.data.google;
        return result;
      });
  }

  isLastAdminById(id) {
    return http.get(`${this.path}/isLastAdminById/${id}`);
  }
  updateById(id, Permission) {
    return http.post(`${this.path}/updateById/${id}`, Permission);
  }
  getUserConnected(id) {
    return http.get(`${this.path}/information/${id}`);
  }
  getUserInformationById(id) {
    return http.get(`${this.path}/getUserInformationById/${id}`);
  }
  getUserPermission(userId) {
    // userId = checkUserId(userId);
    return http
      .get(`${this.path}/getUser/${userId}`)
      .then((response) => {
        if (response.status === 200 && response.data?.data?.[0]) {
          let userData = response.data.data[0];
          return userData.Permission;
        } else {
          console.error(
            "Failed to get user permissions from backend server at /users/getUser/ route. This log may help :\n",
            response
          );
          return Promise.reject(customizeError(response.data));
        }
      })
      .catch((err) => {
        console.error(
          "Failed to get user permissions from backend server at /users/getUser/ route. This log may help :\n",
          err
        );
        return Promise.reject(customizeError(err));
      });
  }

  updatePassword(userId, requestBody) {
    return http.put(`${this.path}/updatePassword/${userId}`, requestBody);
  }

  getByEnterprise(entrepriseId) {
    return http.get(`${this.path}/getByEnterPrise/${entrepriseId}`);
  }

  updateUserTimeZone(id, item) {
    return http.put(`${this.path}/${id}`, item);
  }

  checkPassword(userId, password) {
    return http
      .post(`${this.path}/checkPassword/${userId}`, { password })
      .then((response) => response.data.data);
  }

  checkChangePasswordToken(token) {
    return http
      .post(`${this.path}/checkChangePasswordToken`, { token })
      .then((response) => response.data.data);
  }

  resetPassword(token, newPassword) {
    return http
      .post(`${this.path}/resetPassword/${token}`, { newPassword })
      .then((response) => response.data.data);
  }

  loginInvite(userId) {
    return http
      .get(`${this.path}/login-invite/${userId}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }

  loginInviteNotConnectedUsers() {
    const enterpriseId = checkEnterpriseId();
    return http
      .get(`${this.path}/login-invite-users/${enterpriseId}`)
      .then((res) => getResponseData(res))
      .catch((err) => Promise.reject(customizeError(err)));
  }
  checkAttribute(data){
    return http
      .post(`${this.path}/check-attribute`,data)
      .then((res) => res.data)
      .catch((err) => Promise.reject(customizeError(err)));
  }
  updateMultiple(data) {
    return http.post(`${this.path}/update-multiple`, data)
    .then((res) =>getResponseData(res))
    .catch((err) => Promise.reject(customizeError(err)));
  }
}

export default new UsersService();
