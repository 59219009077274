import { getResponseData } from "@helpers/_functions";

import http from "../../http-common";
import BaseService from "../base.service";

class OrganisatorTasksService extends BaseService {
  constructor() {
    super("organisator-task");
  }

  upsert(data, params = {}) {
    return http
      .post(`${this.path}/`, data, { params })
      .then((response) => getResponseData(response));
  }
  deleteById(id, idProject, params) {
    return http
      .delete(`${this.path}/${id}/${idProject}`, { params })
      .then((response) => getResponseData(response));
  }
  getByProject(id) {
    return http
      .get(`${this.path}/${id}`)
      .then((response) => getResponseData(response));
  }
  getNextReference(segmentId, typeId, preojectId) {
    return http
      .get(`${this.path}/next-reference/${segmentId}/${typeId}/${preojectId}`)
      .then((response) => getResponseData(response));
  }

  nextStep(taskId) {
    return http
      .put(`${this.path}/next-step/${taskId}`)
      .then((response) => getResponseData(response));
  }

  duplicate(taskId) {
    return http.put(`${this.path}/duplicate/${taskId}`, {
      userId: localStorage.getItem("userId")
    });
  }
}

export default new OrganisatorTasksService();
