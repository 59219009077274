import React, { useState, useEffect } from "react";

import { Switch } from "@headlessui/react";

/**
 * @typedef {object} Props
 * @property {string=} color
 * @property {string=} name
 * @property {boolean=} checked
 * @property {Function} handleChange
 * @property {boolean=} switchDouble
 * @property {boolean=} rotate
 * @property {boolean=} disabled
 */

/**
 * @param {Props} props
 * @returns
 */
export default function CustomSwitch(props) {
  const {
    color,
    checked = null,
    handleChange,
    switchDouble,
    rotate = false,
    disabled = false,
    name=""
  } = props;
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (typeof checked === "boolean" && checked !== enabled) {
      setEnabled(checked);
    }
  }, [checked]);

  function onChange(value) {
    handleChange(value);

    if (checked === null) {
      setEnabled(value);
    }
  }

  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      disabled={disabled}
      name={name}
      className={`${switchDouble || enabled ? color : "arh-bg-[#D1D7D5]"} ${
        rotate ? "arh-rotate-180" : "arh-rotate-0"
      } arh-relative arh-inline-flex arh-h-[14px] arh-w-[27px] arh-shrink-0 arh-cursor-pointer arh-rounded-full arh-border-2 arh-border-transparent arh-transition-colors arh-duration-200 arh-ease-in-out focus:arh-outline-none focus-visible:arh-ring-2 focus-visible:arh-ring-white focus-visible:arh-ring-opacity-75`}
    >
      <span
        aria-hidden="true"
        className={`${
          enabled ? "arh-translate-x-3" : "arh-translate-x-0"
        } arh-pointer-events-none arh-inline-block arh-h-[10px] arh-w-[10px] arh-transform arh-rounded-full arh-bg-white arh-shadow-lg arh-ring-0 arh-transition arh-duration-200 arh-ease-in-out`}
      />
    </Switch>
  );
}
